import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';

function OrderFilters({ onApplyFilters, defaultFilters }) {
  const [orderStatus, setOrderStatus] = useState(defaultFilters.orderStatus);
  const [sortBy, setSortBy] = useState(defaultFilters.sortBy);
  const [sortDirection, setSortDirection] = useState(defaultFilters.sortDirection);

  const handleApplyFilters = () => {
    onApplyFilters({ orderStatus, sortBy, sortDirection });
  };

  const orderStatusOptions = [
    { value: 'incart', label: 'In cart' },
    { value: 'pending_approval', label: 'Pending approval' },
    { value: 'approved', label: 'Approved' },
    { value: 'declined', label: 'Declined' },
    { value: 'inprogress', label: 'In progress' },
    { value: 'complete', label: 'Complete' },
    { value: 'canceled', label: 'Canceled' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        mb: 2,
      }}
    >
      <FormControl sx={{ minWidth: 200, flex: 1, mr: 2 }}>
        <InputLabel>Order Status</InputLabel>
        <Select
          multiple
          value={orderStatus}
          onChange={(e) => setOrderStatus(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
          label="Order Status"
        >
          {orderStatusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={orderStatus.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 180, flex: 1, mr: 2 }}>
        <InputLabel>Sort By</InputLabel>
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          label="Sort By"
        >
          <MenuItem value="created_at">Created At</MenuItem>
          <MenuItem value="total_price">Total Price</MenuItem>
          <MenuItem value="order_status">Order Status</MenuItem>
        </Select>
      </FormControl>
      <FormControl component="fieldset" sx={{ minWidth: 180, flex: 1 }}>
        <RadioGroup
          row
          aria-label="sortDirection"
          name="sortDirection"
          value={sortDirection}
          onChange={(e) => setSortDirection(e.target.value)}
        >
          <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
          <FormControlLabel value="desc" control={<Radio />} label="Descending" />
        </RadioGroup>
      </FormControl>
      <Button
        variant="contained"
        onClick={handleApplyFilters}
        sx={{ flexShrink: 0 }}
      >
        Apply
      </Button>
    </Box>
  );
}

OrderFilters.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default OrderFilters;
