import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
} from '@mui/material';
import styles from './styles';

function SignUp(props) {
  const {
    openDialog,
    onClose,
    userData,
    handleChangeUserInput,
    handleSignup,
    openSignInDialog,
  } = props;

  const [confirmPassword, setConfirmPassword] = useState('');

  const checkEmpty = (key) => (userData[key]?.trim().length > 0 ? true : 'The field can not be empty');
  const isEmpty = (key) => (userData[key]?.trim().length === 0);

  const isConfirmPasswordMatch = () => userData.password === confirmPassword;

  const isEmailInvalid = () => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(userData.email) ? false : 'Invalid email format';
  };

  const isPasswordInvalid = () => {
    if (!userData.password) {
      return 'Password cannot be empty';
    }
    if (userData.password.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(userData.password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/\d/.test(userData.password)) {
      return 'Password must contain at least one digit';
    }
    return false;
  };

  const isPhoneInvalid = () => (userData.phone && userData.phone.toString().length >= 10 ? false : 'Phone number must be 10 digits long');

  const checkEveryFieldIsOkay = () => {
    let okay = true;
    ['first_name', 'last_name', 'phone', 'email', 'password'].forEach((key) => {
      okay = okay && (userData[key]?.length > 0);
    });

    // Ensure all fields are filled and passwords match
    return okay
    && !isEmailInvalid()
    && !isPasswordInvalid()
    && !isPhoneInvalid()
    && isConfirmPasswordMatch();
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const onKeyUp = (e) => {
    if (e.key === 'Enter' && checkEveryFieldIsOkay()) {
      handleSignup();
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      onKeyUp={onKeyUp}
      aria-labelledby="login-dialog-title"
      BackdropComponent={() => <Box sx={styles.backdrop} />}
    >
      <DialogTitle id="login-dialog-title">
        <Box sx={styles.titleContainer}>
          <Typography variant="h6">Sign-up</Typography>
          <Typography variant="h6"> / </Typography>
          <Typography
            variant="h6"
            onClick={openSignInDialog}
            sx={styles.clickableText}
          >
            Sign-in
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <TextField
          error={isEmpty('first_name')}
          label="Firstname"
          name="first_name"
          type="text"
          value={userData.first_name}
          helperText={checkEmpty('first_name')}
          onChange={handleChangeUserInput}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          error={isEmpty('last_name')}
          label="Lastname"
          name="last_name"
          type="text"
          value={userData.last_name}
          helperText={checkEmpty('last_name')}
          onChange={handleChangeUserInput}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          error={isEmpty('phone')}
          label="Phone"
          name="phone"
          type="number"
          value={userData.phone}
          helperText={isPhoneInvalid()}
          onChange={handleChangeUserInput}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          error={isEmpty('email')}
          label="Email"
          name="email"
          type="text"
          value={userData.email}
          helperText={isEmailInvalid()}
          onChange={handleChangeUserInput}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          error={isEmpty('password')}
          label="Password"
          name="password"
          type="password"
          value={userData.password}
          helperText={isPasswordInvalid()}
          onChange={handleChangeUserInput}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          error={confirmPassword && !isConfirmPasswordMatch()}
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          helperText={confirmPassword && !isConfirmPasswordMatch() ? 'Passwords do not match' : ''}
          onChange={handleConfirmPasswordChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions sx={styles.actionContainer}>
        <Button
          onClick={handleSignup}
          sx={styles.actionButton}
          disabled={!checkEveryFieldIsOkay()}
        >
          Signup
        </Button>
        <Button onClick={onClose} sx={styles.actionButton}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SignUp.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleChangeUserInput: PropTypes.func.isRequired,
  handleSignup: PropTypes.func.isRequired,
  openSignInDialog: PropTypes.func.isRequired,
};

export default SignUp;
