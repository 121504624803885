import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { request } from '../../common/utils';
import AdminTable from '../common/admin-table';
import AdminModal from '../common/admin-modal';
import OrderFilters from './order-filter';
import styles from './styles';

const orderHeaders = [
  { id: 'id', label: 'Order ID' },
  { id: 'created_at', label: 'Created At', type: 'datetime-local' },
  { composite: true, fields: ['user.first_name', 'user.last_name'], label: 'Renter' },
  { composite: true, fields: ['vendor.first_name', 'vendor.last_name'], label: 'Vendor' },
  { id: 'start_date', label: 'Start Date', type: 'date' },
  { id: 'end_date', label: 'End Date', type: 'date' },
  { id: 'order_status', label: 'Status' },
  { id: 'total_price', label: 'Total Price', type: 'number' },
  { id: 'total_payable', label: 'Total Payable', type: 'number' },
  { id: 'total_discount', label: 'Total Discount', type: 'number' },
  { id: 'total_tax', label: 'Total Tax', type: 'number' },
];

const orderFields = [
  { id: 'start_date', label: 'Start Date', type: 'date' },
  { id: 'end_date', label: 'End Date', type: 'date' },
  { id: 'order_status', label: 'Status', type: 'text' },
  { id: 'total_price', label: 'Total Price', type: 'number' },
];

const orderItemHeaders = [
  { id: 'product_id', label: 'Product ID' },
  { id: 'product.name', label: 'Product Name' },
  { id: 'quantity', label: 'Quantity' },
  { id: 'unit_price', label: 'Unit Price' },
  { id: 'applied_unit_price', label: 'Applied Unit Price' },
  { id: 'unit_price', label: 'Total Price' },
];

function OrderManagement() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type');

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(null); // For edit operations
  const [isModalOpen, setIsModalOpen] = useState(false);

  let defaultOrderStatus = []
  if (type === 'cart') {
    defaultOrderStatus = ['incart'];
  } else if (type === 'active') {
    defaultOrderStatus = ['pending_approval', 'approved', 'inprogress'];
  } else if (type === 'inactive') {
    defaultOrderStatus = ['declined', 'complete', 'canceled'];
  }

  const defaultFilters = {
    orderStatus: defaultOrderStatus,
    sortBy: 'created_at',
    sortDirection: 'desc',
  };

  const applyFilters = (orderParams, filters) => {
    let filtered = [...orderParams]; // Create a copy of orders array

    if (filters.orderStatus && filters.orderStatus.length > 0) {
      filtered = filtered.filter((order) => filters.orderStatus.includes(order.order_status));
    }

    if (filters.sortBy) {
      console.log('Sorting by:', filters.sortBy);
      filtered = filtered.sort((a, b) => {
        let comparison = 0;
        if (filters.sortBy === 'created_at') {
          comparison = new Date(a.created_at) - new Date(b.created_at);
        } else if (filters.sortBy === 'total_price') {
          comparison = a.total_price - b.total_price;
        } else if (filters.sortBy === 'order_status') {
          comparison = a.order_status.localeCompare(b.order_status);
        }
        return filters.sortDirection === 'asc' ? comparison : -comparison;
      });
    }

    setFilteredOrders(filtered);
  };

  const fetchOrders = async () => {
    try {
      const res = await request('/a/get-admin-orders', 'GET');
      setOrders(res.data);
      applyFilters(res.data, defaultFilters); // Apply default filters on initial load
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleAdd = () => {
    setCurrentOrder(null); // Ensure modal is in "add" state
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentOrder(null);
  };

  const handleSave = async (orderData) => {
    try {
      if (currentOrder) {
        // Update operation
        console.log('Updating order:', currentOrder, orderData);
        const res = await request(`/a/order/${currentOrder.id}`, 'PUT', { payload: orderData });
        console.log('Order updated:', res.data);
      } else {
        // Add (create) operation
        console.log('Creating order:', orderData);
        const res = await request('/a/order', 'POST', { payload: orderData });
        console.log('Order created:', res.data);
      }
      fetchOrders(); // Refresh the list after saving
    } catch (error) {
      console.error('Failed to save order:', error);
    } finally {
      handleCloseModal(); // Close the modal after operation
    }
  };

  const handleDeleteOrderItem = async (orderId, orderItemId) => {
    try {
      await request(`/a/order/${orderId}/delete-item/${orderItemId}`, 'POST');
      fetchOrders(); // Refresh the list after deleting
    } catch (error) {
      console.error('Failed to delete order item:', error);
    }
  };

  const handleApplyFilters = (filters) => {
    applyFilters(orders, filters);
  };

  return (
    <div>
      <OrderFilters onApplyFilters={handleApplyFilters} defaultFilters={defaultFilters} />
      <AdminTable
        headers={orderHeaders}
        data={filteredOrders}
        nestedDataKey="order_items"
        nestedHeaders={orderItemHeaders}
        showEditButton
        showDeleteButton
        onEdit={(order) => {
          setCurrentOrder(order);
          setIsModalOpen(true);
        }}
        onDelete={(order) => {
          // Handle delete order
          console.log('Deleting order:', order);
          request(`/a/order/${order.id}`, 'DELETE')
            .then(() => fetchOrders())
            .catch((err) => console.error('Failed to delete order', err));
        }}
        onDeleteNested={(orderId, orderItem) => {
          handleDeleteOrderItem(orderId, orderItem.id);
        }}
      />
      <AdminModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialData={currentOrder || {}}
        onSave={handleSave}
        fields={orderFields}
      />
      <Fab color="primary" aria-label="add" sx={styles.fab} onClick={handleAdd}>
        <AddIcon />
      </Fab>
    </div>
  );
}

export default OrderManagement;
