import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../components/common/page';
import Contact from '../components/contact/contact';
import Home from '../components/home/home';
import ComingSoon from '../components/common/comming-soon';
import NotFoundPage from '../components/common/not-found';
import ChatThreads from '../components/chat/chat-threads';
import ChatMessagesPage from '../components/chat/chat-messages';
import productList from '../components/products/product-list';
import SelfProfile from '../components/self-profile/self-profile';
// Vendor related imports
import ConnectedVendorSignup from '../components/vendor/vendor-signup/vendor-signup';
import VendorDashboard from '../components/vendor/vendor-dashboard/vendor-dashboard';
import AddProduct from '../components/vendor/vendor-products/add-product/add-product';
// import VendorProfile from '../components/vendor/vendor-profile/vendor-profile';
// import VendorOrders from '../components/vendor/vendor-orders/vendor-orders';
import Cart from '../components/checkout/cart/cart';
import OrderConfirmation from '../components/checkout/order-confirmation/order-confirmation';
import OrderList from '../components/order/order-list/order-list';
import VendorOrders from '../components/vendor/vendor-orders/orders/orders';
import OrderDecline from '../components/vendor/vendor-orders/order-decline/order-decline';
import OrderCancel from '../components/vendor/vendor-orders/order-cancel/order-cancel';
import ContactSupport from '../components/vendor/vendor-orders/contact-support/contact-support';
import AddReview from '../components/vendor/vendor-reviews/add-review/add-review';
import VendorProducts from '../components/vendor/vendor-products/products/products';
import ProductDetails from '../components/vendor/vendor-products/product-details/product-details';
import EditProduct from '../components/vendor/vendor-products/edit-product/edit-product';
import VendorReviews from '../components/vendor/vendor-reviews/reviews/reviews';
import TermsAndConditions from '../components/public-pages/terms-and-conditions';
import FAQ from '../components/public-pages/faq';
import AboutUs from '../components/public-pages/about-rentovault';
import NotificationsPage from '../components/notification/notification-page';
import ProductPage from '../components/product-detail/product-page';
import VendorProfile from '../components/view-profile/vendor-profile';
import LoginPage from '../components/LoginPage/LoginPage';
import SignupPage from '../components/signup-page/signup-page';
import AdminDashboard from '../components/admin/dashboard/admin-dashboard';
import CouponManagement from '../components/admin/coupon/coupon-management';
import OrderManagement from '../components/admin/order/order-management';
import UserManagement from '../components/admin/user/user-management';
import ProductManagement from '../components/admin/product/product-management';
import AdminChatThreads from '../components/admin/chats/chat-list';

function Routes({ user }) {
  const isAuthenticated = !!user && !!user.id;

  return (
    <Switch>
      <Route
        path="/login"
        exact
        element={(
          <Page component={LoginPage} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      <Route
        path="/signup"
        exact
        element={(
          <Page component={SignupPage} isAuthenticated={isAuthenticated} requiresLogin preferSignup/>
        )}
      />

      <Route path="/" exact element={<Page component={Home} isAuthenticated={isAuthenticated} />} />
      <Route path="/contact" exact element={<Page component={Contact} isAuthenticated={isAuthenticated} />} />
      <Route path="/comingSoon" exact element={<Page component={ComingSoon} isAuthenticated={isAuthenticated} />} />
      <Route path="/cart" exact element={<Page component={Cart} isAuthenticated={isAuthenticated} />} />
      <Route path="/orders" exact element={(<Page component={OrderList} isAuthenticated={isAuthenticated} requiresLogin />)} />
      <Route path="/order-confirmation/:orderIds" exact element={(<Page component={OrderConfirmation} isAuthenticated={isAuthenticated} requiresLogin />)} />
      <Route path="/terms-and-conditions" exact element={<Page component={TermsAndConditions} isAuthenticated={isAuthenticated} />} />
      <Route path="/faq" exact element={<Page component={FAQ} isAuthenticated={isAuthenticated} />} />
      <Route path="/about-rentovault" exact element={<Page component={AboutUs} isAuthenticated={isAuthenticated} />} />
      <Route path="/notifications" exact element={<Page component={NotificationsPage} isAuthenticated={isAuthenticated} />} />
      <Route path="/product/:id/:userName/:productName" element={<Page component={ProductPage} isAuthenticated={isAuthenticated} />} />
      <Route path="/products" exact element={<Page component={productList} isAuthenticated={isAuthenticated} />} />
      <Route path="/self-profile" exact element={<Page component={SelfProfile} isAuthenticated={isAuthenticated} requiresLogin />} />
      <Route path="/vendor-profile/:userId/:userName" exact element={<Page component={VendorProfile} isAuthenticated={isAuthenticated} />} />

      {/* Vendor related routes */}
      <Route
        path="/v/vendor-signup"
        exact
        element={(
          <Page component={ConnectedVendorSignup} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/vendor-dashboard"
        exact
        element={(
          <Page component={VendorDashboard} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/add-product"
        exact
        element={(
          <Page component={AddProduct} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      {/* <Route
        path="/v/profile"
        exact
        element={(
          <Page component={VendorProfile} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      /> */}
      <Route
        path="/v/orders"
        exact
        element={(
          <Page component={VendorOrders} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/order-decline"
        exact
        element={(
          <Page component={OrderDecline} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/order-cancel"
        exact
        element={(
          <Page component={OrderCancel} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/contact-support"
        exact
        element={(
          <Page component={ContactSupport} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/review-add"
        exact
        element={(
          <Page component={AddReview} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/products"
        exact
        element={(
          <Page component={VendorProducts} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/product/:productId"
        exact
        element={(
          <Page component={ProductDetails} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/product/:productId/edit"
        exact
        element={(
          <Page component={EditProduct} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/v/reviews"
        exact
        element={(
          <Page component={VendorReviews} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      {/* Common */}
      <Route
        path="/chat"
        exact
        element={(
          <Page component={ChatThreads} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />
      <Route
        path="/chat/messages/:targetUserId"
        exact
        element={(
          <Page component={ChatMessagesPage} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      {/* Admin related routes */}
      <Route
        path="/a/admin-dashboard"
        exact
        element={(
          <Page component={AdminDashboard} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      <Route
        path="/a/coupon-management"
        exact
        element={(
          <Page component={CouponManagement} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      <Route
        path="/a/order-management"
        exact
        element={(
          <Page component={OrderManagement} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      <Route
        path="/a/user-management"
        exact
        element={(
          <Page component={UserManagement} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      <Route
        path="/a/product-management"
        exact
        element={(
          <Page component={ProductManagement} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      <Route
        path="/a/chats"
        exact
        element={(
          <Page component={AdminChatThreads} isAuthenticated={isAuthenticated} requiresLogin />
        )}
      />

      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />

    </Switch>
  );
}

Routes.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Routes;
