import React, { useState, useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { request } from '../../common/utils'; // Assuming you have a utility for API requests
import SummaryCard from './summary-card';

const useStyles = (theme) => ({
  row: {
    marginBottom: 4,
  }
});

function AdminDashboard() {
  const [couponCount, setCouponCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [activeOrderCount, setActiveOrderCount] = useState(0);
  const [inactiveOrderCount, setInactiveOrderCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [chatThreadCount, setChatThreadCount] = useState(0);

  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    request('/a/summary', 'GET')
      .then((res) => {
        setCouponCount(res.data.coupon_count);
        setCartCount(res.data.cart_count);
        setActiveOrderCount(res.data.active_order_count);
        setInactiveOrderCount(res.data.inactive_order_count);
        setUserCount(res.data.user_count);
        setVendorCount(res.data.vendor_count);
        setProductCount(res.data.product_count);
        setChatThreadCount(res.data.chat_thread_count);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Box>
      {/* Coupons */}
      <Grid container spacing={12} sx={styles.row}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Total Coupons"
            value={couponCount}
            link="/a/coupon-management"
          />
        </Grid>
      </Grid>

      {/* Orders */}
      <Grid container spacing={12} sx={styles.row}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Orders In Cart"
            value={cartCount}
            link="/a/order-management?type=cart"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Active Orders"
            value={activeOrderCount}
            link="/a/order-management?type=active"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Inactive Orders"
            value={inactiveOrderCount}
            link="/a/order-management?type=inactive"
          />
        </Grid>
      </Grid>

      {/* Users and Vendors */}
      <Grid container spacing={12} sx={styles.row}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Total Users"
            value={userCount}
            link="/a/user-management?role=user"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Total Vendors"
            value={vendorCount}
            link="/a/user-management?role=vendor"
          />
        </Grid>
      </Grid>

      {/* Products */}
      <Grid container spacing={12} sx={styles.row}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Total Products"
            value={productCount}
            link="/a/product-management"
          />
        </Grid>
      </Grid>

      {/* Chats */}
      <Grid container spacing={12} sx={styles.row}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Chat Threads"
            value={chatThreadCount}
            link="/a/chats"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminDashboard;
