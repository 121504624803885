const useStyles = (theme) => ({
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formContainer: {
    paddingX: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '40%',
      paddingX: theme.spacing(8),
      paddingY: theme.spacing(4),
    },
  },
  heading: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  photoUpload: {
    width: '150px',
    height: '150px',
    marginBottom: '32px',
  },
});

export default useStyles;
