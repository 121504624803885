import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box, Button, Typography, useTheme, Autocomplete, TextField as MUITextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { request } from './utils';
import { formattedDateRange } from '../../utils/formatting-utils';
import LocationField from './styled-components/styled-autocomplete-location';
import { setSearchFilter, clearSearchFilter } from '../../redux/actions/product-action';

const useStyles = (theme) => ({
  searchBarContainer: {
    paddingY: 2,
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1%',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingX: 5,
      maxWidth: theme.spacing(50),
    },
  },
  searchDateFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 2,
    },
  },
  searchDateField: {
    flex: 1,
  },
  searchButtonBox: {
    width: '100%',
    flex: 1,
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  searchButton: {
    borderRadius: theme.spacing(2),
    paddingX: theme.spacing(6),
  },
  collapsedContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    marginX: theme.spacing(4),
    paddingX: theme.spacing(2),
    paddingY: theme.spacing(1),
    borderRadius: 4,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(50),
    },
  },
  collapsedContainerEmpty: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    marginX: theme.spacing(4),
    paddingX: theme.spacing(2),
    paddingY: theme.spacing(1),
    borderRadius: 4,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(30),
    },
  },
  // collapsedContainerSearchTerm: {
  //   display: 'flex',
  //   justifyContent: 'center',
  // },
  // collapsedContainerAddressDate: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  // },
});

function SearchBar(props) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const categories = query.get('categories') || '';

  const {
    searchFilter,
    setSearchFilters,
    clearSearch,
    handleClickOnSearch,
    isCollapsed,
    handleClickOnCollapsed,
    clearOnStart,
  } = props;

  const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [, setError] = useState('');

  useEffect(() => {
    if (clearOnStart) {
      console.log('Clearing search params');
      clearSearch();
    }
  }, [clearOnStart]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await request('/get-tags', 'GET');
        setTags(response.data); // Assuming the response has a data property
      } catch (err) {
        setError('Failed to fetch tags.');
      }
    };

    fetchTags();
  }, []);

  const onSelectedPlace = (place) => {
    console.log('Place selected', place);
    setSearchFilters({
      field: 'place',
      value: place,
    });
  };

  const handleOnChange = (e) => {
    setSearchFilters({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleSearchTermChange = (newValue) => {
    console.log('At handleSearchTermChnage');
    setSearchFilters({
      field: 'searchText',
      value: newValue,
    });
  };

  const handleStartDateChange = (newValue) => {
    const formattedDate = newValue.toISOString().split('T')[0];
    setSearchFilters({
      field: 'startDate',
      value: formattedDate,
    });
    setEndDatePickerOpen(true);
  };

  const handleEndDateChange = (newValue) => {
    const formattedDate = newValue.toISOString().split('T')[0];
    setSearchFilters({
      field: 'endDate',
      value: formattedDate,
    });
    setEndDatePickerOpen(false);
  };

  const getPostalCode = (place) => {
    const typesOrder = ['postal_code', 'route', 'locality', 'political'];
    console.log('Place searched:', place);
    const displayComponent = typesOrder.map(
      (type) => place.address_components.find((component) => component.types.includes(type)),
    ).find((component) => component);
    console.log('Display component:', displayComponent);

    return displayComponent ? displayComponent.long_name : '';
  };

  const customSx = {
    marginBottom: 4,
  };

  const renderExpandedSearchBar = () => (
    <Box sx={styles.searchBarContainer}>
      <LocationField
        label="Where do you need it?"
        placeholder="Enter city or address"
        place={searchFilter.place}
        onSelectedPlace={onSelectedPlace}
        required
      />

      <Autocomplete
        name="searchText"
        options={tags.map((option) => option.tag)}
        fullWidth
        forcePopupIcon={false}
        clearOnBlur={false}
        value={searchFilter.searchText ? searchFilter.searchText : ''}
        onChange={
          (event, newValue) => {
            handleSearchTermChange(newValue);
          }
        }
        renderInput={(params) => (
          <MUITextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            name="searchText"
            value={searchFilter.searchText ? searchFilter.searchText : ''}
            onChange={handleOnChange}
            placeholder="E.g. camera, tent, kayak"
            label="What are you looking for?"
            fullWidth
            required
            sx={customSx}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      <Box sx={styles.searchDateFieldContainer}>
        <DatePicker
          name="startDate"
          label="Start date (Optional)"
          placeholder="Start date"
          slotProps={{ textField: { variant: 'outlined', InputLabelProps: { shrink: true } } }}
          value={searchFilter.startDate ? dayjs(searchFilter.startDate) : null}
          onChange={handleStartDateChange}
          sx={styles.searchDateField}
          disablePast
          closeOnSelect
        />

        <DatePicker
          name="endDate"
          label="End date (Optional)"
          placeholder="End date"
          slotProps={{ textField: { variant: 'outlined', InputLabelProps: { shrink: true } } }}
          value={searchFilter.endDate ? dayjs(searchFilter.endDate) : null}
          onChange={handleEndDateChange}
          sx={styles.searchDateField}
          disablePast
          minDate={searchFilter.startDate ? dayjs(searchFilter.startDate).add(1, 'd') : null}
          closeOnSelect
          open={isEndDatePickerOpen}
        />
      </Box>

      <Box sx={styles.searchButtonBox}>
        <Button
          variant="contained"
          onClick={handleClickOnSearch}
          sx={styles.searchButton}
          disabled={!(searchFilter.place && searchFilter.searchText && searchFilter.searchText !== '')}
        >
          Search
        </Button>
      </Box>

    </Box>
  );

  const renderEmptySearchBar = () => (
    <Box sx={styles.collapsedContainerEmpty} onClick={handleClickOnCollapsed}>
      <Typography variant="subtitle2">
        Search
      </Typography>
      <SearchIcon />
    </Box>
  );

  const renderCollapsedSearchBar = () => (
    <Box sx={styles.collapsedContainer} onClick={handleClickOnCollapsed}>
      <Typography variant="subtitle2" sx={styles.collapsedContainerSearchTerm}>
        {searchFilter.searchText ? searchFilter.searchText : (categories || 'Anything')}
      </Typography>
      <Typography variant="subtitle2">
        {' | '}
      </Typography>
      <Typography variant="subtitle2">
        {searchFilter.place ? getPostalCode(searchFilter.place) : 'Anywhere'}
      </Typography>
      <Typography variant="subtitle2">
        {' | '}
      </Typography>
      <Typography variant="subtitle2">
        {formattedDateRange(searchFilter.startDate, searchFilter.endDate)}
      </Typography>
    </Box>
  );

  if (!isCollapsed) {
    return renderExpandedSearchBar();
  } else {
    if (categories) {
      return renderEmptySearchBar();
    } else {
      return renderCollapsedSearchBar();
    }
  }
}

SearchBar.propTypes = {
  searchFilter: PropTypes.oneOfType([PropTypes.object]),
  setSearchFilters: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  handleClickOnSearch: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  handleClickOnCollapsed: PropTypes.func,
  clearOnStart: PropTypes.bool,
};

SearchBar.defaultProps = {
  searchFilter: {},
  isCollapsed: false,
  handleClickOnCollapsed: null,
  clearOnStart: false,
};

const mapStateToProps = (state) => ({
  searchFilter: state.product.searchFilter,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      setSearchFilters: setSearchFilter,
      clearSearch: clearSearchFilter,
    },
  ),
);

export default enhance(SearchBar);
