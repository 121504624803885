import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import Badge from '@mui/material/Badge';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  useTheme,
  // Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from '../../resource/images/logo.svg';
import { request } from './utils';
import NotificationDropdown from '../notification/notification-dropdown';
import StyledButton from './styled-components/styled-button';

const useStyles = (theme) => ({
  topContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolbarContainer: {
    height: '60px',
    '@media (min-width: 320px)': {
      height: '100px',
    },
  },
  logoContainer: {
    flexGrow: 1,
    userSelect: 'none',
  },
  logo: {
    color: 'white',
    width: 120,
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginLeft: 2,
    marginBottom: '5px', // The logo is not vertically centered without this
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  vendorDashboardButton: {
    color: 'white',
  },
  becomeVendorButton: {
    color: 'white',
  },
  navbarIcon: {
    color: '#FFFFFF',
    paddingRight: 1.5,
  },
});

function NavBarDesktop(props) {
  const {
    accountMenuAnchorEl,
    onClickAccount,
    onCloseAccount,
    openSignInDialog,
    user,
    toggleDrawerOpen,
    unreadNotificationCount,
    unreadChatCount,
    cartItemCount,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);


  const isVendorPage = location.pathname.startsWith('/v/');
  const isVendor = user && user.id && user.role === 'vendor';

  const [notifications, setNotifications] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const isNotificationDropdownOpen = Boolean(notificationAnchorEl);

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  // Function to fetch notifications
  const fetchNotifications = () => {
    request('/get-push-notifications', 'GET')
      .then((res) => {
        setNotifications(res.data); // Assuming 'data' contains the notifications
      })
      .catch((err) => {
        console.error('Error fetching notifications:', err);
      });
  };

  useEffect(() => {
    console.log(user);
    if (isNotificationDropdownOpen) {
      fetchNotifications();
    }
  }, [isNotificationDropdownOpen]);

  const redirectToHome = () => {
    navigate('/');
  };

  const redirectToChat = () => {
    navigate('/chat');
  };

  const redirectToCart = () => {
    navigate('/cart');
  };

  const redirectToVendorSignup = () => {
    if (user && user.id) {
      navigate('/v/vendor-signup');
    } else {
      navigate('/signup?redirectUrl=/v/vendor-signup');
    }
  };

  const redirectToVendorDashboard = () => {
    navigate('/v/vendor-dashboard');
  }

  return (
    <Box sx={styles.topContainer}>
      <AppBar position="static">
        <Toolbar sx={styles.toolbarContainer}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box component="div" sx={styles.logoContainer}>
            <Box
              component="img"
              src={logo}
              alt="Rentovault"
              sx={styles.logo}
              onClick={redirectToHome}
            />
          </Box>

          {
            !isVendorPage && (
              isVendor ? (
                <StyledButton
                variant="text"
                label="Vendor Dashboard"
                sx={styles.vendorDashboardButton}
                onClick={redirectToVendorDashboard}
              />
              ) : (
                <StyledButton
                  variant="text"
                  label="Become A Vendor"
                  sx={styles.becomeVendorButton}
                  onClick={redirectToVendorSignup}
                />
              )
            )
          }

          { user && user.id && (
            <IconButton sx={styles.navbarIcon} onClick={handleNotificationClick}>
              <Badge badgeContent={unreadNotificationCount} color="warning">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}

          { user && user.id && (
            <IconButton sx={styles.navbarIcon} onClick={redirectToChat}>
              <Badge badgeContent={unreadChatCount} color="warning">
                <ChatRoundedIcon />
              </Badge>
            </IconButton>
          )}

          { user && user.id && (
            <IconButton sx={styles.navbarIcon} onClick={redirectToCart}>
              <Badge badgeContent={cartItemCount} color="warning">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          )}

          <Menu
            anchorEl={accountMenuAnchorEl}
            open={Boolean(accountMenuAnchorEl)}
            onClose={onCloseAccount}
          >
            <MenuItem onClick={openSignInDialog}>Sign in</MenuItem>
            <MenuItem onClick={() => onClickAccount(user)}>Sign up</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <NotificationDropdown
        anchorEl={notificationAnchorEl}
        open={isNotificationDropdownOpen}
        onClose={handleNotificationClose}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </Box>
  );
}

NavBarDesktop.propTypes = {
  accountMenuAnchorEl: PropTypes.oneOfType([PropTypes.elementType]),
  onClickAccount: PropTypes.func.isRequired,
  onCloseAccount: PropTypes.func.isRequired,
  openSignInDialog: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  unreadNotificationCount: PropTypes.number,
  unreadChatCount: PropTypes.number.isRequired,
  cartItemCount: PropTypes.number,
};

NavBarDesktop.defaultProps = {
  unreadNotificationCount: 0,
  cartItemCount: 0,
  accountMenuAnchorEl: null,
};

export default NavBarDesktop;
