import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import DOMPurify from 'dompurify';
import GoogleMapReact from 'google-map-react';
import dayjs from 'dayjs';
import {
  Box, Typography, Avatar, Grid, Stack, useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useStyles from './styles';
import { request } from '../common/utils';
import StyledButton from '../common/styled-components/styled-button';
import RatingInfo from '../common/rating-info';
import calculateRating from '../../utils/common-utils';
import { store } from '../../redux/configureStore';
import { addAlert } from '../../redux/actions/alert-actions';

function ProductDetailsComponent({ user, details }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const location = useLocation();

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    console.log(details);
    const query = new URLSearchParams(location.search);
    const fetchedStartDate = query.get('start') || '';
    const fetchedEndDate = query.get('end') || '';

    setStartDate(dayjs(fetchedStartDate));
    setEndDate(dayjs(fetchedEndDate));
  }, [location.search]);

  const handleStartDateChange = (newValue) => {
    const formattedDate = newValue.toISOString().split('T')[0];
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (newValue) => {
    const formattedDate = newValue.toISOString().split('T')[0];
    setEndDate(formattedDate);
  };

  const redirectToChat = (userId) => {
    navigate(`/chat/messages/${userId}`);
  };

  const formatDateToYYYYMMDD = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(dateString)) {
      return dateString;
    }
    const date = new Date(dateString);
    if (!Number.isNaN(date.getTime())) {
      return new Intl.DateTimeFormat('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(date).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2');
    }
    console.error('Invalid date format:', dateString);
    return null;
  };

  const redirectToLogin = () => {
    navigate(`/login?redirectUrl=${location.pathname}${location.search}`);
  };

  const redirectToVendorProfile = (productDetail) => {
    navigate(`/vendor-profile/${productDetail.owner_id}/${productDetail.owner_slug}`);
  };

  const addToCart = () => {
    if (!(user && user.id)) {
      redirectToLogin();
    } else {
      request('/add_to_cart', 'POST', {
        payload: {
          product_id: details.id,
          quantity: 1,
          start_date: formatDateToYYYYMMDD(startDate),
          end_date: formatDateToYYYYMMDD(endDate),
          vendor_id: details.owner.id,
        },
      })
        .then((response) => {
          console.log('Added to cart:', response);
          const alertMessage = (
            <>
              Added to cart successfully!
              <a href="/cart" style={styles.linkStyle}>View Cart</a>
            </>
          );
          store.dispatch(addAlert({ message: alertMessage, severity: 'success' }));
        })
        .catch((error) => {
          console.error('Error adding to cart:', error);
          store.dispatch(addAlert({ message: 'Failed to add to cart', severity: 'error' }));
        });

      ReactGA.event({
        category: 'Product added to cart',
        action: 'addToCart',
        label: details.name,
      });
    }
  };

  return (
    <Box sx={styles.topContainer}>
      <Box>
        <Box>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item container alignItems="center" xs>
              <Avatar
                sx={styles.userAvatarStyle}
                src={details.owner_profile_picture.image}
              >
                {details.owner_first_name}
              </Avatar>
              <Stack alignItems="left">
                <Typography
                  variant="h6"
                  sx={styles.userNameStyle}
                  onClick={() => redirectToVendorProfile(details)}
                >
                  {`${details.owner_first_name} ${details.owner_last_name}`}
                </Typography>
                <RatingInfo
                  rating={calculateRating(details.reviews)}
                  numReviewers={details.reviews.length}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack alignItems="center">
                <StyledButton
                  variant="contained"
                  label="Contact"
                  onClick={() => redirectToChat(details.owner.user_id)}
                />
              </Stack>
            </Grid>
          </Grid>
          <Typography variant="h1" sx={styles.titleStyle}>
            {details.name}
          </Typography>
          <Typography variant="subtitle1" sx={styles.priceStyle}>
            {`$${details.price} / day`}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box sx={styles.AddToCartContainer}>
          <Box sx={styles.DatePickerContainer}>
            <DatePicker
              name="startDate"
              label="Start date"
              placeholder="Start date"
              slotProps={{ textField: { variant: 'outlined', InputLabelProps: { shrink: true } } }}
              value={dayjs(startDate)}
              onChange={handleStartDateChange}
              disablePast
              closeOnSelect
            />
            <DatePicker
              name="endDate"
              label="End date"
              placeholder="End date"
              slotProps={{ textField: { variant: 'outlined', InputLabelProps: { shrink: true } } }}
              value={dayjs(endDate)}
              onChange={handleEndDateChange}
              disablePast
              closeOnSelect
              minDate={startDate ? dayjs(startDate).add(1, 'd') : null}
            />
          </Box>
          <Box sx={styles.ButtonContainer}>
            <StyledButton
              sx={styles.cartButton}
              variant="contained"
              label="Add to cart"
              onClick={addToCart}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ marginY: 2 }}>
        <hr />
      </Box>

      <Box>
        <Typography variant="h6" sx={styles.descriptionTitleStyle}>
          About the item
        </Typography>
        <Typography
          sx={styles.descriptionStyle}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details.description) }}
        />
      </Box>

      <Box sx={{ marginY: 2 }}>
        <hr />
      </Box>

      <Box sx={styles.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          defaultCenter={{ lat: Number(details.latitude), lng: Number(details.longitude) }}
          defaultZoom={14}
          onGoogleApiLoaded={({ map, maps }) => new maps.Circle({
            strokeColor: theme.palette.primary.main,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: theme.palette.primary.light,
            fillOpacity: 0.3,
            map,
            center: { lat: Number(details.latitude), lng: Number(details.longitude) },
            radius: 275,
          })}
        />
      </Box>
    </Box>
  );
}

// Define PropTypes
ProductDetailsComponent.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  details: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ProductDetailsComponent.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(ProductDetailsComponent);
