import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from "react-helmet";
import ProductImages from './product-images';
import ProductDetails from './product-detail';
import { request } from '../common/utils';

const useStyles = (theme) => ({
  BreadcrumbContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
});

function ProductPage() {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [productDetails, setProductDetails] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { id, user_name, product_name } = useParams();

  useEffect(() => {

    request(`/products/${id}`, 'GET')
      .then((res) => {
        setProductImages(res.data.images || []);
        setProductDetails(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [location.search]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const renderBreadcrumb = () => {
    if (!productDetails.category_full_path) return null;

    const categories = productDetails.category_full_path.split(',');

    return (
      <Box sx={styles.BreadcrumbContainer}>
        <Link to="/">Home</Link>
        {categories.map((category, index) => {
          return (
            <span key={index}>
              {" > "}
              <Link to={`/products?categories=${category}`}>
                {category.trim()}
              </Link>
            </span>
          );
        })}
      </Box>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{`Rent ${productDetails.name}`}</title>
        <meta name='description' content={`Rent ${productDetails.name} for ${productDetails.price} per day from ${productDetails.city}`} />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Grid item xs={12} md={12} marginLeft={4}>
            {renderBreadcrumb()}
          </Grid>
          <ProductImages images={productImages} />
        </Grid>
        <Grid item xs={12} md={7}>
          <ProductDetails details={productDetails} />
        </Grid>
      </Grid>
    </div>
  );
}

export default ProductPage;
