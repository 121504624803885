import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { addToCart } from '../../utils/api-utils';

function SignupPage({ user }) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (user && user.id) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get("redirectUrl");
      const pendingCartItem = localStorage.getItem("pendingCartItem");
      if (redirectUrl === "addToCart" && pendingCartItem) {
        console.log('cartItem', pendingCartItem);
        const { details, startDate, endDate } = JSON.parse(pendingCartItem);
        addToCart(details, startDate, endDate, navigate);
        localStorage.removeItem("pendingCartItem");
      } else if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/");
      }
    }
  }, [user]);

  return (
    <div />
  );
}

SignupPage.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

SignupPage.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(SignupPage);
