import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, useTheme, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import SearchBar from '../common/search-bar';
import Banner from '../common/banner';
import HowItWorks from '../common/how-it-works';
import CategoryCards from './category-cards';

const useStyles = (theme) => ({
  container: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  bannerContainer: {
    paddingX: 1,
    [theme.breakpoints.up('md')]: {
      paddingX: 10,
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      paddingX: 10,
    },
  },
  title: {
    fontSize: '1.6rem',
    textAlign: 'center',
    overflow: 'visible',
    marginBottom: 1,
    '& span': {
      fontWeight: 400,
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
      marginBottom: 2,
    },
  },
  subtitle: {
    fontSize: '1.4rem',
    textAlign: 'center',
    marginBottom: 1,
    [theme.breakpoints.up('md')]: {
      fontSize: '2.2rem',
      marginBottom: 4,
    },
  },
});

function Home(props) {
  const {
    searchFilter,
  } = props;

  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  const redirectToProductSearch = () => {
    navigate(`/products?search=${searchFilter.searchText}`);
  };

  return (
    <div>
      <Helmet>
        <title>{`Rentovault`}</title>
        <meta name="description" content="Rentovault - Your peer-to-peer platform for renting photography and outdoor gear."></meta>
      </Helmet>

      <Grid container spacing={2} alignItems="center" sx={styles.container}>
        <Grid item xs={12} md={6}>
          <Box sx={styles.bannerContainer}>
            <Banner />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={styles.searchContainer}>
            <Typography variant="h1" sx={styles.title}>
              Rent <span>Outdoors</span> and <span>Photography</span> gears
            </Typography>
            <Typography variant="h1" sx={styles.subtitle}>
              from your neighbours
            </Typography>
            <SearchBar
              handleClickOnSearch={redirectToProductSearch}
              clearOnStart
            />
          </Box>
        </Grid>

        <CategoryCards />

        <HowItWorks />

      </Grid>
    </div>
  );
}

Home.propTypes = {
  searchFilter: PropTypes.oneOfType([PropTypes.object]),
};

Home.defaultProps = {
  searchFilter: {},
};

const mapStateToProps = (state) => ({
  searchFilter: state.product.searchFilter,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(Home);
