const useStyles = (theme) => ({

  topContainer: {
    paddingX: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingX: theme.spacing(10),
    },
  },

  userAvatarStyle: {
    bgcolor: 'secondary.main',
    marginRight: 2,
  },

  userNameStyle: {
    gutterBottom: true,
    component: 'div',
  },

  userRatingStyle: {
    variant: 'body2',
    color: '#FFA41C',
  },

  titleStyle: {
    fontSize: '1.4rem !important',
    color: 'text.primary',
    marginTop: '16px',
    fontWeight: 'bold',
  },

  descriptionStyle: {
    variant: 'body1',
    color: 'text.secondary',
    marginTop: '8px',
  },

  cardActionsStyle: {
    justifyContent: 'space-between',
  },

  priceStyle: {
    color: 'primary',
    fontWeight: 'bold',
  },

  mapContainer: {
    height: '80vw',
    width: '100%',
    marginY: 4,
    [theme.breakpoints.up('md')]: {
      height: '30vw',
    },
  },

  depositStyle: {
    variant: 'body2',
    color: 'text.secondary',
    paddingX: '16px',
    paddingBottom: '16px',
  },

  emptyGrid: {
    display: { xs: 'none', md: 'block' },
  },

  mainImageBoxStyle: {
    width: '100%',
    marginX: 2,
  },

  galleryStyle: {
    '.image-gallery-thumbnails-container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '100%',
    },
    '.image-gallery-thumbnails-wrapper': {
      marginBottom: 2,
    },
    '.image-gallery-thumbnail': {
      border: 'none',
      borderRadius: '8px', // Rounded edges for thumbnails
      margin: 1, // White space around thumbnails
      '&.active': {
        borderColor: 'primary.main',
      },
    },
    '.image-gallery-image': {
      marginLeft: 2,
      borderRadius: '50px', // Rounded edges for main image
    },
    '.image-gallery-slide': {
      alignItems: 'center',
    },
  },

  AddToCartContainer: {
    display: 'flex',
    marginTop: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },

  DatePickerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },

  ButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
  },

  cartButton: {
    marginTop: 2,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },

  linkStyle: {
    marginLeft: 5, // Adjust the styling as needed
    textDecoration: 'none', // Example to remove underline
    color: 'blue', // Example to set link color
    // Add other styles as needed
  },
});

export default useStyles;
