import React, { useState, useEffect } from 'react';
import { request } from '../../common/utils';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AdminChatThreads() {
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState({});

  const fetchThreads = async () => {
    try {
      const res = await request('/a/chat-threads', 'GET');
      setThreads(res.data);
    } catch (err) {
      console.error('Failed to fetch chat threads:', err);
    }
  };

  const fetchMessages = async (threadId) => {
    try {
      const res = await request(`/a/chat-messages/${threadId}`, 'GET');
      setMessages((prev) => ({ ...prev, [threadId]: res.data }));
    } catch (err) {
      console.error(`Failed to fetch messages for thread ${threadId}:`, err);
    }
  };

  useEffect(() => {
    fetchThreads();
  }, []);

  return (
    <div>
      <Typography variant="h4">Chat Threads</Typography>
      {threads.map((thread) => (
        <Accordion key={thread.id} onChange={() => fetchMessages(thread.id)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Chat between {thread.first_user.first_name} {thread.first_user.last_name} and {thread.second_user.first_name} {thread.second_user.last_name} -
              Latest message at {new Date(thread.latest_message_timestamp).toLocaleString()}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {messages[thread.id] ? (
              messages[thread.id].map((msg) => (
                <Typography key={msg.id}>
                  {msg.from_user_id === thread.first_user.id ? thread.first_user.first_name : thread.second_user.first_name}: {msg.message}
                </Typography>
              ))
            ) : (
              <Typography>Loading messages...</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default AdminChatThreads;
