import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  Drawer,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import NavBarMobile from './navbar-mobile';
import NavBarDesktop from './navbar-desktop';
import LogIn from './login';
import { setUser, clearUser } from '../../redux/actions/user-actions';
import { setUnreadNotificationCount } from '../../redux/actions/unread-notification-counter-action';
import { setChatThreads } from '../../redux/actions/chat-actions';
import { request } from './utils';
import { store } from '../../redux/configureStore';
import { addAlert } from '../../redux/actions/alert-actions';

import styles from './styles';
import LeftSidebarMobile from './left-side-bar-mobile';
import LeftSidebarDesktop from './left-side-bar-desktop';
import SignUp from './signup';
import StyledLoadingComponent from './styled-components/styled-loading-component';
import LOGIN_EXPIRY_TIME_SECONDS from './constants';
import websocketService from '../../utils/web-socket-service';
import AlertsDisplay from './styled-components/alerts-display';
import Footer from './styled-components/styled-footer';

function Page(props) {
  const {
    component,
    setUserDetails,
    clearUserDetails,
    user,
    timeLoggedIn,
    isAuthenticated,
    requiresLogin,
    preferSignup,
    loading,
    unreadNotificationCount,
    unreadChatCount,
  } = props;

  const PageComponent = component;
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [openLoginDialog, setOpenLogInDialog] = useState(false);
  const [signUpDialod, setSignUpDialog] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userData, setUserData] = useState({});

  const disconnectWebsocket = () => {
    websocketService.disconnect();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    disconnectWebsocket();
    clearUserDetails();
    request('/logout/', 'POST')
      .then(() => {
        store.dispatch(addAlert({ message: 'Logged Out Successfully', severity: 'success' }));
        if (requiresLogin) {
          navigate('/');
        }
      })
      .catch((err) => console.log(err));
  };

  // Function to fetch notifications and update Redux state
  const fetchAndSetNotifications = () => {
    request('/get-unread-notifications', 'GET')
      .then((res) => {
        const unreadCount = res.data.filter((notification) => !notification.read).length;
        store.dispatch(setUnreadNotificationCount(unreadCount));
      })
      .catch((err) => {
        console.error('Error fetching notifications:', err);
      });
  };

  // Function to fetch unread message notifications and update Redux state
  const fetchAndSetUnreadChatCount = () => {
    request(`/threads/${user.id}`, 'GET')
      .then((res) => {
        // const unreadCount = res.data.filter((thread) => !thread.latest_message_seen).length;
        // console.log('Unread chat count: ', unreadCount);
        store.dispatch(setChatThreads(res.data));
      })
      .catch((err) => {
        console.error('Error fetching chat threads:', err);
      });
  };

  // Needs to be the first useEffect
  useEffect(() => {
    if (timeLoggedIn) {
      const elapsedTime = Date.now() - timeLoggedIn;
      if (elapsedTime >= LOGIN_EXPIRY_TIME_SECONDS * 1000) {
        handleLogout();
      }
    }
  }, [timeLoggedIn]);

  // When the user logs in
  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      websocketService.connect();
      fetchAndSetNotifications();
      fetchAndSetUnreadChatCount();
    }
  }, [user]);

  useEffect(() => {
    if (requiresLogin && !isAuthenticated) {
      if (preferSignup) {
        setSignUpDialog(true);
      } else {
        setOpenLogInDialog(true);
      }
    }
  }, [requiresLogin, isAuthenticated]);

  const handleChangeUserInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onClickAccount = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseAccount = () => setAnchorEl(null);

  const closeSignInDialog = () => {
    console.log('Closing sign in dialog');
    setOpenLogInDialog(false);
    setEmail(null);
    setPassword(null);
  };

  const closeSignUpDialog = () => {
    setSignUpDialog(false);
    setUserData({});
  };

  const openSignInDialog = () => {
    onCloseAccount();
    setOpenLogInDialog(true);
    closeSignUpDialog();
  };

  const openSignUpDialog = () => {
    setSignUpDialog(true);
    closeSignInDialog();
  };

  const handleSignup = () => request(
    '/create-user/',
    'POST',
    {
      payload: {
        ...userData,
        username: userData.email,
        role: 'user',
        status: 'active',
      },
    },
  )
    .then((res) => {
      closeSignUpDialog();
      setUserDetails(res.data);
      store.dispatch(addAlert({ message: `Welcome to Rentovault, ${res.data.first_name}`, severity: 'success' }));
    })
    .catch((err) => console.log(err));

  const handleLoginComplete = (response) => {
    closeSignInDialog();
    setUserDetails(response.data);
    store.dispatch(addAlert({ message: `Welcome back, ${response.data.first_name}`, severity: 'success' }));
    // TODO: Navigate to back url if the token has expired
    // navigate(-1);
  };

  const handleLogin = () => request(
    '/login/',
    'POST',
    {
      payload: { email, password },
    },
  )
    .then((response) => {
      handleLoginComplete(response);
    })
    .catch((err) => console.log(err));

  const handleGoogleLogin = (credential) => {
    const idToken = credential;
    const url = '/login-google';

    const options = {
      payload: {
        token: idToken,
      },
    };
    closeSignInDialog();
    return request(url, 'POST', options)
      .then((response) => {
        handleLoginComplete(response);
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };

  const toggleDrawerOpen = () => {
    setDrawerOpen((isOpen) => !isOpen);
  };

  return (
    <div>
      <StyledLoadingComponent loading={loading} />

      {isDesktop ? (
        <NavBarDesktop
          accountMenuAnchorEl={anchorEl}
          onClickAccount={onClickAccount}
          onCloseAccount={onCloseAccount}
          openSignInDialog={openSignInDialog}
          user={user}
          toggleDrawerOpen={toggleDrawerOpen}
          unreadNotificationCount={unreadNotificationCount}
          unreadChatCount={unreadChatCount}
        />
      ) : (
        <NavBarMobile
          accountMenuAnchorEl={anchorEl}
          onClickAccount={onClickAccount}
          onCloseAccount={onCloseAccount}
          openSignInDialog={openSignInDialog}
          user={user}
          toggleDrawerOpen={toggleDrawerOpen}
          unreadNotificationCount={unreadNotificationCount}
          unreadChatCount={unreadChatCount}
        />
      )}

      {
        requiresLogin && !isAuthenticated ? (
          <Box sx={styles.requireLogin}>
            <Typography>
              Please Login to access this page
            </Typography>
          </Box>
        ) : (
          <Box sx={styles.pageComponentContainer}>
            <PageComponent />
          </Box>
        )
      }
      <Footer />

      <LogIn
        openLoginDialog={openLoginDialog}
        onClose={closeSignInDialog}
        email={email}
        password={password}
        setEmail={setEmail}
        setPassword={setPassword}
        handleLogin={handleLogin}
        openSignUpDialog={openSignUpDialog}
        handleGoogleLogin={handleGoogleLogin}
      />
      <SignUp
        openDialog={signUpDialod}
        onClose={closeSignUpDialog}
        userData={userData}
        handleChangeUserInput={handleChangeUserInput}
        handleSignup={handleSignup}
        openSignInDialog={openSignInDialog}
      />
      <Drawer
        sx={styles.drawer}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawerOpen}
      >
        {isDesktop ? (
          <LeftSidebarDesktop
            toggleDrawerOpen={toggleDrawerOpen}
            openSignInDialog={openSignInDialog}
            openSignUpDialog={openSignUpDialog}
            handleLogout={handleLogout}
          />
        ) : (
          <LeftSidebarMobile
            toggleDrawerOpen={toggleDrawerOpen}
            openSignInDialog={openSignInDialog}
            openSignUpDialog={openSignUpDialog}
            handleLogout={handleLogout}
          />
        )}
      </Drawer>

      <AlertsDisplay />
    </div>
  );
}

Page.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType]).isRequired,
  setUserDetails: PropTypes.func.isRequired,
  clearUserDetails: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]),
  timeLoggedIn: PropTypes.number,
  isAuthenticated: PropTypes.bool.isRequired,
  requiresLogin: PropTypes.bool,
  preferSignup: PropTypes.bool,
  loading: PropTypes.bool,
  unreadNotificationCount: PropTypes.number.isRequired,
  unreadChatCount: PropTypes.number.isRequired,
};

Page.defaultProps = {
  user: {},
  timeLoggedIn: null,
  requiresLogin: false,
  preferSignup: false,
  loading: false,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
  timeLoggedIn: state.user.timeLoggedIn,
  loading: state.loading.loading,
  unreadNotificationCount: state.unreadNotificationCounter
    ? state.unreadNotificationCounter.count : 0,
  unreadChatCount: state.chat
    ? state.chat.unreadCount : 0,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      setUserDetails: setUser,
      clearUserDetails: clearUser,
    },
  ),
);

export default enhance(Page);
