import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import useStyles from './styles';
import { setUser } from '../../../redux/actions/user-actions';
import { request } from '../../common/utils';
import UploadableAvatar from '../../common/uploadable-avatar';
import StyledTextField from '../../common/styled-components/styled-textfield';
import LocationField from '../../common/styled-components/styled-autocomplete-location';
import StyledButton from '../../common/styled-components/styled-button';
import { getPostalCode } from '../../../utils/geolocation';

function VendorSignup({ user, setUserDetails }) {
  const [photo, setPhoto] = useState(user.profile_picture?.image);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phone ? user.phone : '');
  const [address, setAddress] = useState({});
  const [aboutMe, setAboutMe] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    // Check if the user is a vendor and has vendor_info
    if (user && user.role === 'vendor' && user.vendor_info) {
      navigate('/v/vendor-dashboard');
    }
  });

  const handlePhotoChange = (photoFile) => {
    request(
      '/upload-user-image/',
      'POST',
      {
        isFormData: true,
        axiosOptions: { params: { user_id: user.id } },
        payload: { file: photoFile },
      },
    )
      .then((res) => {
        console.log(res);
        console.log(res.data.s3_url);
        setPhoto(res.data.s3_url);
      })
      .catch((err) => console.log(err));
  };

  const handlePlaceSelected = (place) => {
    setAddress(place);
  };

  const handleSignup = () => {
    // Prepare vendor info and send the signup request
    const formattedAddress = address.formatted_address;
    const formattedAddressList = formattedAddress.split(',');

    const vendorInfo = {
      user_id: user.id,
      about_me: aboutMe,
      shipping_enabled: false,
      phone: phoneNumber,
      profile_picture_url: photo,
      address: {
        address_line1: formattedAddressList[0].trim(),
        address_line2: '',
        city: formattedAddressList[1].trim(),
        province: formattedAddressList[2].trim().split(' ')[0].trim(),
        postal_code: getPostalCode(address),
        country: formattedAddressList[3].trim(),
        latitude: address.geometry.location.lat(),
        longitude: address.geometry.location.lng(),
        google_places_id: address.place_id,
      },
    };

    console.log(vendorInfo);

    request(
      '/create-vendor/',
      'POST',
      {
        payload: { ...vendorInfo },
      },
    )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setUserDetails(res.data);
          navigate('/vendor-dashboard');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={styles.topContainer}>
      <Paper
        elevation={isDesktop ? 2 : 0}
        sx={styles.formContainer}
      >
        <Typography variant="h3" sx={styles.heading}>Start Your Vendor Journey</Typography>

        <UploadableAvatar
          photoUrl={photo}
          style={styles.photoUpload}
          onPhotoChange={handlePhotoChange}
        />
        <StyledTextField
          label="Phone Number"
          placeholder="647-123-4567"
          fullWidth
          type="number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
        <LocationField
          label="Pickup Address For Renters"
          placeholder="E.g. 123 Main St, Toronto, ON"
          onSelectedPlace={handlePlaceSelected}
          required
          addressOnly
        />
        <StyledTextField
          multiline
          rows={4}
          label="About Me"
          placeholder="Tell us a little about yourself! Share your interests, experience, or anything you'd like others to know."
          fullWidth
          value={aboutMe}
          onChange={(e) => setAboutMe(e.target.value)}
          required
        />
        <StyledButton
          variant="contained"
          label="Join as Vendor"
          onClick={handleSignup}
          disabled={!(!photo.includes('dummy-profile-pic') && phoneNumber && address && aboutMe)}
        />
      </Paper>
    </Box>
  );
}

VendorSignup.propTypes = {
  setUserDetails: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]),
};

VendorSignup.defaultProps = {
  user: {},
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      setUserDetails: setUser,
    },
  ),
);

export default enhance(VendorSignup);
