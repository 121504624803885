const drawerWidth = 300;
const styles = {
  requireLogin: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
  },
  pageComponentContainer: {
    width: '100%',
    marginTop: 4,
    paddingBottom: 8,
  },
  topContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    flexGrow: 1,
    userSelect: 'none',
  },
  logo: (theme) => ({
    color: 'white',
    width: 120,
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginLeft: 2,
    marginBottom: '5px', // The logo is not vertically centered without this
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  }),
  drawerHeader: {
    height: '10vh',
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    justifyContent: 'flex-end',
  },
  drawerBody: {
    height: '70vh',
  },
  buttonItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(245, 245, 245)',
    },
  },
  sideBarButtonText: {
    fontWeight: 'bold',
    marginLeft: 3,
  },
  headerImage: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 4,
  },
  profile: {
    marginRight: 1,
  },
  headerName: {
    display: 'flex',
    flexDirection: 'column',
  },
  namePart: {
    fontSize: '16px',
  },
  addrPart: {
    fontSize: '10px',
  },
  drawerAction: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  drawer: {
    width: `${drawerWidth}px`,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: `${drawerWidth}px`,
    },
  },
  navbarIcon: {
    color: '#FFFFFF',
    paddingRight: 1.5,
  },
  navbarBadge: (theme) => ({
    color: theme.palette.secondary.light,
  }),
  toolbarContainer: {
    height: '60px',
    '@media (min-width: 320px)': {
      height: '100px',
    },
  },
  footerContainer: {
    height: '50vh',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  contactAndTaglineContainer: (theme) => ({
    flex: 4,
    backgroundColor: theme.palette.primary.main,
    paddingX: 2,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (min-width: 540px)': {
      paddingX: 15,
      flexDirection: 'row',
    },
  }),
  copyrightContainer: (theme) => ({
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    borderTop: '1px solid rgba(255, 255, 255, .1)',
    paddingX: 2,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'space-between',
    '@media (min-width: 540px)': {
      paddingX: 15,
      textAlign: 'left',
    },
  }),
  aboutUsHeader: {
    fontSize: '32px',
    fontWeight: 1000,
    color: '#ffffff',
  },
  footerText: {
    fontSize: '14px',
    lineHeight: '1.8',
    color: '#dce4e6',
    letterSpacing: '.1px',
  },
  aboutUsbodyMargin: {
    marginTop: 3,
  },
  aboutUsContainer: {
    flex: 1,
    '@media (min-width: 540px)': {
      padding: 4,
      paddingLeft: 0,
    },
  },
  socialLinksContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialLinksIcon: {
    color: '#007bff',
    transition: 'transform .5s',
    '&:hover': {
      color: '#FA6800',
      background: 'none',
      transform: 'scale(1.5)',
    },
  },
  banner: {
    width: '100%',
    height: 'auto',
  },
  fade: {
    opacity: '.4',
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    backdropFilter: 'blur(20px)',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 1,
    padding: 2,
  },
  clickableText: (theme) => ({
    color: theme.palette.primary.light,
    cursor: 'pointer',
  }),
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionButton: {
    backgroundColor: 'rgba(24, 84, 120)',
    color: 'white',
    // fontSize: '18px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#FA6800',
      color: 'black',
    },
  },
  textOnlyButton: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      background: 'none',
      fontWeight: 'bold',
    },
  },
  addressAuto: {
    width: '98%',
    borderRadius: '5px',
  },

  // Styles for Uploadable Avatar
  uploadableAvatar: {
    iconButton: {
      width: '100%',
      height: '100%',
    },
    avatar: {
      width: '100%',
      height: '100%',
    },
    emptyAvatar: {
      width: '100%',
      height: '100%',
      overflow: 'visible',
    },
    addIconAvatar: {
      position: 'relative',
      left: '40%',
      top: '25%',
      width: '35%',
      height: '35%',
      border: '1px solid',
      backgroundColor: 'white',
    },
    icon: {
      position: 'relative',
      left: '30%',
      width: '50%',
      height: '50%',
    },
    input: {
      width: '100%',
      height: '100%',
    },
  },
};

export default styles;
