import React from 'react';
import { Box, useTheme } from '@mui/material';
import banner1 from '../../resource/banner/banner1.webp';
import banner2 from '../../resource/banner/banner2.webp';
import banner3 from '../../resource/banner/banner3.webp';
import banner4 from '../../resource/banner/banner4.webp';

const useStyles = (theme) => ({
  rowImages: {
    gap: 1,
    marginY: 1,
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      gap: 4,
      marginY: 4,
    },
  },
  bannerImage: {
    borderRadius: 2,
    width: '45%',
    aspectRatio: '1/1',
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
});

function Banner() {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <>
      <Box sx={styles.rowImages}>
        <Box component="img" src={banner1} sx={styles.bannerImage} />
        <Box component="img" src={banner2} sx={styles.bannerImage} />
      </Box>
      <Box sx={styles.rowImages}>
        <Box component="img" src={banner3} sx={styles.bannerImage} />
        <Box component="img" src={banner4} sx={styles.bannerImage} />
      </Box>
    </>
  );
}

export default Banner;
