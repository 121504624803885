import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Avatar, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import styles from './styles';

function UploadableAvatar({ photoUrl, onPhotoChange, style }) {
  const [avatar, setAvatar] = useState(photoUrl);

  useEffect(() => {
    // Update avatar state if photo_url prop changes
    setAvatar(photoUrl);
  }, [photoUrl]);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onPhotoChange(file);
    }
  };

  return (
    <div style={style}>
      <IconButton
        sx={styles.uploadableAvatar.iconButton}
        color="primary"
        component="label"
      >
        {
          avatar.includes('dummy-profile-pic') && (
            <Avatar sx={styles.uploadableAvatar.addIconAvatar}>
              <AddIcon />
            </Avatar>
          )
        }

        <Avatar
          sx={avatar.includes('dummy-profile-pic') ? styles.uploadableAvatar.emptyAvatar : styles.uploadableAvatar.avatar}
          src={avatar}
        >
        </Avatar>
        <input
          style={styles.uploadableAvatar.input}
          type="file"
          hidden
          accept="image/*"
          onChange={handleAvatarChange}
        />
      </IconButton>
    </div>
  );
}

UploadableAvatar.propTypes = {
  onPhotoChange: PropTypes.func.isRequired,
  photoUrl: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
};

UploadableAvatar.defaultProps = {
  photoUrl: null,
  style: null,
};

export default UploadableAvatar;
