import ReactGA from 'react-ga4';

import { request } from '../components/common/utils';
import { store } from '../redux/configureStore';
import { addAlert } from '../redux/actions/alert-actions';

const formatDateToYYYYMMDD = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (regex.test(dateString)) {
    return dateString;
  }
  const date = new Date(dateString);
  if (!Number.isNaN(date.getTime())) {
    return new Intl.DateTimeFormat('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2');
  }
  console.error('Invalid date format:', dateString);
  return null;
};

export const addToCart = (details, startDate, endDate, navigate) => {
  request('/add_to_cart', 'POST', {
    payload: {
      product_id: details.id,
      quantity: 1,
      start_date: formatDateToYYYYMMDD(startDate),
      end_date: formatDateToYYYYMMDD(endDate),
      vendor_id: details.owner.id,
    },
  })
    .then((response) => {
      console.log('Added to cart:', response);
      navigate('/cart');
    })
    .catch((error) => {
      console.error('Error adding to cart:', error);
      store.dispatch(addAlert({ message: 'Failed to add to cart', severity: 'error' }));
    })

  ReactGA.event({
    category: 'Product added to cart',
    action: 'addToCart',
    label: details.name,
  });
};
